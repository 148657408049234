const parallel = async (...args) => {
    return await Promise.all(args)
}

const parallelArr = async (args) => {
    return await Promise.all(args)
}

const tryAsyncFunc =
    (func) =>
    async (...params) => {
        let result = null
        let error = null
        try {
            result = await func(...params)
        } catch (e) {
            error = e
            console.error(e)
        }

        if (typeof result === "undefined") {
            error = new Error("No return value of func")
            console.log(error)
        }

        return [result, error]
    }

module.exports = {
    tryAsyncFunc,
    parallel,
    parallelArr
}
