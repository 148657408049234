import i18n from "config/i18n"

export const MONTHS = [
    { value: 1, label: i18n.global.t("constants.months.01") },
    { value: 2, label: i18n.global.t("constants.months.02") },
    { value: 3, label: i18n.global.t("constants.months.03") },
    { value: 4, label: i18n.global.t("constants.months.04") },
    { value: 5, label: i18n.global.t("constants.months.05") },
    { value: 6, label: i18n.global.t("constants.months.06") },
    { value: 7, label: i18n.global.t("constants.months.07") },
    { value: 8, label: i18n.global.t("constants.months.08") },
    { value: 9, label: i18n.global.t("constants.months.09") },
    { value: 10, label: i18n.global.t("constants.months.10") },
    { value: 11, label: i18n.global.t("constants.months.11") },
    { value: 12, label: i18n.global.t("constants.months.12") }
]

export const YEARS = []
;(() => {
    let startDate = 2022
    let endDate = new Date().getFullYear()

    for (let i = startDate; i < endDate; i++) {
        YEARS.push({
            value: i,
            label: i
        })
    }

    YEARS.push({
        value: endDate,
        label: endDate
    })
})()
